import React from "react"
import entgeltHeader from "../../../assets/images/products/netversys/entgelt_header.svg"
import wmEntgelte from "../../../assets/images/products/netversys/wm-entgeltmanagement.svg"
import Sidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import WichtigeMerkmale from "../WichtigeMerkmale"
import LexikonBadgesRandomSection from "../../lexikon/LexikonBadgesRandomSection"
import { StaticImage } from "gatsby-plugin-image"


const Entgeltmanagement = () => {

  return (
    <>
      <section className="services-details-area ptb-100">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <img src={entgeltHeader} alt="Entgeltmanagement" />
              </div>

              <div className="services-details-desc">
                <span className="sub-title">NETVERSYS</span>
                <h1 className={"pt-3"}>Entgeltmanagement</h1>
                <p>
                  Die Möglichkeit, die mit einem Transportdienstleister verhandelten Entgelte zu hinterlegen ist
                  essenziell, um eine automatische Carrierfindung nach der Regel "entgelt-günstigst" zu gewährleisten.
                  ERP Systeme oder Datawarehouses des Kunden wollen ebenso gerne wissen, wieviel eine Sendung gekostet
                  hat, um diese Daten für weitere Kalkulationen oder für eine Auskunftsfähigkeit im ERP-System zu
                  nutzen. NETVERSYS bietet hierzu ein mehrstufiges Verfahren der Entgeltehinterlegung an, wobei
                  natürlich auch eine Hinterlegung von Servicepreisen nicht fehlen darf.
                </p>

                <WichtigeMerkmale items={[
                  "Hinterlegung von Einkaufspreisen",
                  "Hinterlegung von Verkaufspreisen",
                  "Zonen und Services berücksichtigt",
                  "Hinterlegung von FlatFee oder Gewichtsmatrix"
                ]} image={<img src={wmEntgelte}
                               alt="Entgeltmanagement Merkmale"
                               className={"ml-lg-4 ml-0"} />} />

                <h3>Relevantes aus dem Lexikon</h3>

                <LexikonBadgesRandomSection />
              </div>
            </Col>

            <Col lg={4} md={12}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="services-area pt-4 pb-70 bg-f1f8fb">
        <Container>
          <span className="mb-2 text-primary"><i className={"bx bxs-bullseye"} /> Im Detail</span>
          <h2>Entgeltmanagement in der Praxis</h2>
          <Row className={"mt-5"}>
            <Col md={6} xs={12}>
              <StaticImage
                src="../../../assets/images/products/netversys/screenshots/nv_pricemanager_framed.png"
                alt="Entgeltmanagement Screenshot"
                placeholder="blurred"
                width={600}
              />
            </Col>
            <Col md={{ offset: 1, span: 5 }} xs={{ offset: 1, span: 11 }}>
              <p>
                Wo viele andere Versandsysteme die Einkaufspreise für Pakete und Services hinterlegen können, geht
                NETVERSYS einen Schritt weiter und ermöglicht, Verkaufspreise zu hinterlegen. Dies ist besonders
                relevant bei Kontraktlogistikern, die ein gewisses Sendungsvolumen bei Carriern ankaufen und diese an
                Ihre Kunden weiterverkaufen. Hierbei ist die Pflege von Verkaufspreisen essenziell, um Gewinnmargen und
                weitere Kennzahlen schnell zu generieren.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Entgeltmanagement
