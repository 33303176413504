import React from "react"
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import Footer from "../../../components/App/Footer"
import Entgeltmanagement from "../../../components/ProductDetails/netversys/Entgeltmanagement"
import Seo from "../../../components/App/Seo"

const EntgeltmanagementPage = () => {
  return (
    <Layout>
      <Seo title={"Kostenoptimierung mit Entgeltmanagement"}
           description={"Das Entgeltmanagement ist essentiell für ein korrektes Reporting " +
           "von Versandkosten und hilf bei der Verhandlung mit Versanddienstleistern."}
           image={"/images/og/netversys-main-og.png"}>
              <script type="application/ld+json">{`
                        {
                          "@context": "https://schema.org",
                          "@type": "BreadcrumbList",
                          "itemListElement": [{
                            "@type": "ListItem",
                            "position": 1,
                            "name": "Home",
                            "item": "https://logentis.de"
                          },{
                            "@type": "ListItem",
                            "position": 2,
                            "name": "Versandsoftware",
                            "item": "https://logentis.de/versandsoftware/"
                          },{
                            "@type": "ListItem",
                            "position": 3,
                            "name": "Entgeltmanagement",
                            "item": "https://logentis.de/versandsoftware/netversys/entgeltmanagement/"
                          }]
                        }
                        `}
              </script>
      </Seo>
      <Navbar />
      <Entgeltmanagement />
      <Footer />
    </Layout>
  );
}

export default EntgeltmanagementPage
